<template>
  <v-row justify="center" align="center" class="display-center">
    <v-col v-if="!loading" cols="12" sm="12" md="12" style="position: relative">
      <l-map
        @update:center="centerUpdated"
        class="fill-height blockMap"
        :style="`height: ${height}; border-radius: 15px; color:${color}`"
        ref="map"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer :url="url"></l-tile-layer>

        <div class="Ubication-user">
          <v-btn
            small
            @click="locationUserfn"
            :loading="loadingLocation"
            style="border-radius: 10px"
          >
            <v-icon :color="color" small class="mr-1"
              >mdi-crosshairs-gps</v-icon
            >
            Ubicame en el mapa
          </v-btn>
        </div>
        <div class="expand-btn" v-if="expandBtn">
          <span class="mr-2 mt-2 mb-0 text-btn-map"><b>Expandir mapa</b></span>
          <v-btn fab small @click="expand">
            <v-icon :color="color">mdi-arrow-expand-all</v-icon>
          </v-btn>
        </div>
        <l-marker
          :lat-lng="[center[0], center[1]]"
          @update:lat-lng="positionUpdated"
          :draggable="draggableActive"
          class="filter-disable"
          :icon="getImage"
        >
          <l-tooltip>Mueva el marcador a la nueva ubicación</l-tooltip>
        </l-marker>
      </l-map>
      <div class="searchGeo" v-if="search">
        <v-text-field
          :color="color"
          style="background-color: #fff"
          filled
          append-icon="mdi-magnify"
          rounded
          light
          dense
          hide-details
          v-model="searchTerm"
          v-on:keyup.enter="geoQuerySearch()"
          v-on:keyup.escape="searchResults = []"
          placeholder="Busca una dirección o ingresa las coordenadas"
        ></v-text-field>
        <transition name="slide-fade" appear>
          <div v-if="searchResults.length" class="matches">
            <ul>
              <li
                class="text-caption"
                v-for="result in searchResults"
                :key="result.properties.osm_id"
                @click="geoQuerySelect(result)"
              >
                <v-icon :color="color">mdi-map-marker-radius</v-icon>
                {{ result.name }}
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { db, fb } from "@/firebase";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
  // LGeoJson,
} from "vue2-leaflet";
require("leaflet-routing-machine");
require("leaflet-control-geocoder");

import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";

export default {
  name: "map-c",

  components: {
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    LTooltip,
    VGeosearch,
    // LGeoJson,
  },
  props: [
    "color",
    "expandBtn",
    "height",
    "locationUpdate",
    "search",
    "draggableActive",
    "isModePay",
  ],
  data() {
    return {
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: false,
        searchLabel: "Buscar",
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      polygons: [],
      searchTerm: null,
      center: [14.0997836, -87.1882845],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
      snackbar: false,
      geoCoder: null,
      snackbarText: "",
      map: null,
      loadingLocation: false,
      isFleetOcho: false,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      globals: null,
      locationUser: null,
      loading: true,
      saving: false,
      geojson: null,
      searchResults: [],
      fillColor: "#ff0000",
      serverUrl: null,
    };
  },
  watch: {
    center(e) {
      this.checkPoint(e).then((response) => {
        this.$emit("valuePoint", response);
      });
    },
    locationUpdate(e) {
      this.center = [e[0], e[1]];
    },
    searchTerm(e) {
      this.geoQuerySearch();
    },
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 2;
        }, 100);
      }
    },
  },
  computed: {
    ...mapGetters(["infoData"]),
    typeFleet() {
      return this.infoData.qrFleetType;
    },
    kmConfigBussine() {
      return this.infoData.qrKmConfiguration;
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1];
    },

    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1];
    },
    styleFunction() {
      const fillColor = this.fillColor;
      return () => {
        return {
          weight: 2,
          color: "#ff0000",
          opacity: 0.1,
          fillColor: fillColor,
          fillOpacity: 0.1,
        };
      };
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    getImage() {
      return L.icon({
        iconUrl: require("@/assets/avatar-default.png"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [16, 52],
        shadowAnchor: [32, 59],
        popupAnchor: [0, -76],
      });
    },
    businessLocation() {
      // _lat:14.087962800005501
      // _long:-87.1829934
      return this.infoData.geoAddress.geopoint;
    },
  },

  methods: {
    ...mapActions(["Alert_"]),
    expand() {
      this.$emit("Expand", true);
    },
    positionUpdated(e) {
      this.center = [e.lat, e.lng];
      this.calculateDistance(this.businessLocation, e).then((response) => {
        // console.log(response, "DIs ");
        this.calculateDeliveryCost(
          response.distance,
          this.kmConfigBussine
        ).then((resp) => {
          this.$emit("cost", resp.deliveryCost);
        });
      });
      this.$emit("updated", this.center);
    },

    calculateDeliveryCost(distance, business) {
      return new Promise((resolve, reject) => {
        try {
          let deliveryCost = 0;
          if (distance < business.maxDistance) {
            deliveryCost =
              business.pickupDay +
              business.dropoffDay +
              business.dayCostKm * Math.ceil(distance);
          } else {
            deliveryCost =
              business.pickupDay +
              business.dropoffDay +
              business.dayCostKm * Math.ceil(distance) +
              (Math.ceil(distance) - business.maxDistance) *
                business.extraKmCharge;
          }
          // console.warn(deliveryCost);
          return resolve({
            deliveryCost,
          });
        } catch (error) {
          return reject(
            "Imposible calcular el precio del envío, la información del comercio está incompleta."
          );
        }
      });
    },

    calculateDistance(businessLocation, userLocation) {
      return new Promise(async (resolve, reject) => {
        let pointsQuery = `${businessLocation._long},${businessLocation._lat};${userLocation.lng},${userLocation.lat}`;
        let distance = await axios.get(`${this.serverUrl}${pointsQuery}`);

        // console.debug("distance ", distance);
        if (distance.status != 200) {
          return reject(
            "Imposible calcular la distancia del recorrido. Revise que los puntos geográficos ingresados se hayan escrito correctamente y se encuentre dentro de la zona de cobertura."
          );
        }
        if (
          !distance.data ||
          !distance.data.routes ||
          !distance.data.routes.length
        ) {
          return reject(
            "Imposible calcular la distancia del recorrido. Revise que los puntos geográficos ingresados se hayan escrito correctamente y se encuentre dentro de la zona de cobertura."
          );
        }
        let route = distance.data.routes[0];
        distance = distance.data.routes[0].distance / 1000;
        distance = Math.round(distance);
        // console.debug("distance", distance);
        return resolve({
          route,
          distance,
          measurementUnit: "KM",
        });
      });
    },
    geoQuerySearch() {
      // console.debug(this.searchTerm);
      this.searchResults = [];

      if (this.searchTerm) {
        var re =
          /[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/;
        let result = re.exec(this.searchTerm);

        if (result && result.length) {
          this.geoCoder.setQuery(result[0]);
          this.geoCoder._geocode();
        } else {
          this.geoCoder.setQuery(
            `Honduras, ${this.city ? this.city.name : ""} ,${this.searchTerm}`
          );
          this.geoCoder._geocode();
        }
      }
    },
    async init() {
      if (this.typeFleet == "ocho") {
        await this.$binding("globals", db.collection(`settings`).doc("global"));
        this.geojson = JSON.parse(this.globals.coverageZone);

        this.geojson.features = this.geojson.features.filter(
          (e) => e.properties.color != "transparent"
        );

        this.isFleetOcho = true;
        this.loading = false;
      } else {
        this.isFleetOcho = false;
      }
    },
    centerUpdated(e) {
      if (this.draggableActive) {
        this.center = [e.lat, e.lng];
        this.$emit("updated", this.center);
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    success() {
      if (this.center[0] && this.center[1]) {
        this.saving = true;
        this.$emit("success", this.center);
      } else {
        this.snackbarText = "Ubicación no válida.";
        this.snackbar = true;
      }
    },
    handleError() {
      this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente.";
      this.snackbar = true;
      this.saving = false;
    },
    locationUserfn() {
      // console.debug(this.locationUser);
      if (this.locationUser) {
        this.latitude = this.locationUser.latitude;
        this.longitude = this.locationUser.longitude;
        this.center = [this.latitude, this.longitude];
      } else {
        this.getLocationsUser();
      }
    },
    async getLocationsUser() {
      this.loadingLocation = true;
      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition((position) => {
          this.locationUser = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          // navigator.geolocation.watchPosition((e) => {
          //   if (e) {

          //     // console.debug(e)
          //     // this.latitude = e.coords.latitude;
          //     // this.longitude = e.coords.longitude;
          //     // this.center = [this.latitude, this.longitude];
          //     this.loadingLocation = false;
          //   }
          // });
        });
        this.loadingLocation = false;
      } else {
        this.Alert_({
          text: "😥 no hemos podido obtener tu ubicación",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-alert",
          type: "error",
        });
        this.loadingLocation = false;
      }
      this.loadingLocation = false;
    },
    async geoQuerySelect(selection) {
      this.searchResults = [];
      this.searchTerm = "";
      var locationS = [selection.center.lat, selection.center.lng];
      // console.warn(selection.center.lat, selection.center.lng);
      let isValid = await this.checkPoint(locationS);

      if (isValid) {
        this.center = locationS;
      } else {
        // console.debug("El área seleccionada no está disponible.");
        this.Alert_({
          text: "😥 El área seleccionada no está disponible.",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-alert",
          type: "error",
        });
        // this.$store.commit(
        //   "setNotification",
        //   "El área seleccionada no está disponible."
        // );
        // this.$store.commit("setNotification", true);
      }
    },

    checkPoint(point) {
      return new Promise((resolve, reject) => {
        let response = false;
        var x = point[0];
        var y = point[1];

        for (let p = 0; p < this.polygons.length; p++) {
          const polygon = this.polygons[p];

          let polyPoints = polygon.getLatLngs();
          polyPoints = polyPoints[0];

          for (
            var i = 0, j = polyPoints.length - 1;
            i < polyPoints.length;
            j = i++
          ) {
            var xi = polyPoints[i].lat;
            var yi = polyPoints[i].lng;

            var xj = polyPoints[j].lat;
            var yj = polyPoints[j].lng;

            var intersect =
              yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

            if (intersect) {
              response = !response;
            }
          }
        }
        return resolve(!this.typeFleet ? true : response);
      });
    },
  },
  async mounted() {
    let serverUrl = await db.collection(`settings`).doc("urls").get();
    this.serverUrl = serverUrl.data().drivingUrl;

    console.log("serverUrl", this.serverUrl);

    await this.init();

    await this.$nextTick(async () => {
      await this.getLocationsUser();
      this.map = this.$refs.map.mapObject;

      let geoJsonLayer = L.geoJSON(this.geojson, {
        style: function (feature) {
          return {
            color: feature.properties.color,
            weight: 0,
            opacity: 1,
            fillOpacity: 0.1,
          };
        },
      }).addTo(this.map);
      this.geoCoder = L.Control.geocoder({
        geocoder: this.useGoogleSearch
          ? new L.Control.Geocoder.Google(
              "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho"
            )
          : "",

        defaultMarkGeocode: false,
      })
        .on("startgeocode", (e) => {})
        .on("finishgeocode", (e) => {
          if (e.results.length > 0) {
            this.searchResults = e.results;
          }
        })
        .addTo(this.map);

      await geoJsonLayer.eachLayer((layer) => {
        if (layer.feature.properties.color != "transparent") {
          this.polygons.push(layer);
        }
      });
      if (this.draggableActive && this.locationUpdate) {
        this.center = [this.locationUpdate[0], this.locationUpdate[1]];
        // console.log("draggableActive", true);
      } else {
        this.center = [14.0997836, -87.1882845];
        // console.log("draggableActive", false);
        setTimeout(() => {
          this.center = [14.0997835, -87.1882844];
        }, 1000);
      }
    });

    this.zoom = 1;
    this.loading = false;
  },
};
</script>

<style lang="scss" >
#mapid {
  height: 40vh;
}

.text-btn-map {
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29);
}

.leaflet-marker-icon {
  border-radius: 100%;
  /* background-color: #f96921; */
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}

.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}

@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }

  25% {
    left: 0;
    top: 0;
  }
}

.vue2leaflet-map {
  z-index: 1;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
}

.geosearch .leaflet-bar .leaflet-control-geosearch {
  position: fixed;
  right: 0px;
  bottom: 0px !important;
}

.glass {
  width: 300px;
  height: 50px;
  border-radius: 10px;
  padding: 5px 0px 5px 5px;
  background-color: aliceblue;
}

.results {
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
}

.reset {
  position: absolute;
  right: -40px;
  border-radius: 10px;
  top: 10px;
}

.leaflet-control-attribution {
  display: none !important;
}

.Ubication-user {
  position: absolute;
  // top: calc(100% - 50px);
  bottom: 10px;
  left: 10px !important;
  z-index: 1000;
}

.expand-btn {
  position: absolute;
  display: flex;
  top: 10px;
  align-items: center;
  right: 10px !important;
  z-index: 1000;
}

.filter-disable {
  /* mix-blend-mode: saturation; */
  filter: grayscale(100%) !important;
}

.searchGeo {
  position: absolute;
  z-index: 1;
  top: 20px;
  width: 70%;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
}

.matches {
  margin-top: 20px;
  background-color: #fff;
  padding: 10px;

  border-radius: 10px;

  ul {
    li {
      margin-bottom: 5px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
      transition: 0.3s ease-in-out;
      padding: 5px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
        border-radius: 5px;
      }
    }
  }
}

.leaflet-control-geocoder {
  display: none;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px) !important;
  opacity: 0;
}
</style>