var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',_vm._l((_vm.orders),function(item){return _c('div',{key:item.IdRespal,staticClass:"wrap-list-prd my-2 card",on:{"click":function($event){(_vm.tap = !_vm.tap), (_vm.active = item.IdRespal)}}},[_c('div',{staticClass:"wrap-prd rounded-lg"},[_c('v-list-item-content',{staticClass:"rounded-lg"},[(item && item.businessData)?_c('v-list-item-title',[_c('v-avatar',{staticClass:"mb-1",attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":item.businessData.logo.original || _vm.imgDefault,"lazy-src":item.businessData.logo.loading || _vm.imgDefault}})],1),_c('b',{staticClass:"ml-2",staticStyle:{"line-height":"0"}},[_vm._v(_vm._s(item.businessData.shortName))])],1):_vm._e(),_c('v-list-item-title',{staticClass:"text-captionfont-weight-black",staticStyle:{"max-width":"350px","font-weight":"700"}},[_c('b',{staticClass:"quality"},[_vm._v(_vm._s(item.orderP.quantity)+"x ")]),_c('span',{staticClass:"text-captionfont-weight-black"},[_vm._v(_vm._s(item.name)+" ("+_vm._s(_vm._f("currency")(item.isDiscount ? item.discountPrice : item.price))+") ")])]),(item.addonsArray.filter(
              (e) => e.valueResp))?_c('v-list-item-subtitle',{staticClass:"caption"},_vm._l((item.addonsArray.filter(
              (e) => e.valueResp
            )),function(addon,index){return _c('ul',{key:index},[_c('li',[(addon.controlType == 'radio')?_c('span',{staticClass:"text-addons-captions",staticStyle:{"max-width":"250px","font-size":"15px"}},[_vm._v(" "+_vm._s(addon.valueResp.originalProduct.name)+" ("+_vm._s(_vm._f("currency")(addon.valueResp.price))+")")]):_vm._e()]),_vm._l((Object.assign([], addon.valueResp)),function(i,index){return _c('li',{key:index},[(addon.controlType == 'checkbox')?_c('span',{staticClass:"text-addons-captions",staticStyle:{"max-width":"250px","font-size":"15px"}},[_vm._v(" "+_vm._s(i.originalProduct.name)+" ("+_vm._s(_vm._f("currency")(i.price))+")")]):_vm._e()])}),_vm._l((Object.assign(
                [],
                addon.products.filter((x) => x.resul > 0)
              )),function(spin,index){return _c('li',{key:index},[(addon.controlType == 'spin')?_c('span',{staticClass:"text-addons-captions",staticStyle:{"max-width":"250px","font-size":"15px"}},[_vm._v(" ("+_vm._s(spin.resul)+"x)-"+_vm._s(spin.originalProduct.name)+" ("+_vm._s(_vm._f("currency")(spin.price))+")")]):_vm._e()])})],2)}),0):_vm._e(),_c('v-list-item-subtitle',{staticClass:"caption"},[_c('b',{},[_vm._v("Total del producto ("+_vm._s(_vm._f("currency")(item.priceDOM))+")")])])],1),(item.images[0])?_c('div',{staticClass:"item-image-order-card",style:(`background-image:url('${item.images[0]}');`)}):_vm._e()],1),_c('div',{staticClass:"btn_delete-edit"},[_c('div',{staticClass:"edit",on:{"click":function($event){return _vm.EditI(item)}}},[_c('v-icon',{attrs:{"color":"#b37c02"}},[_vm._v("mdi-pencil")])],1),_c('div',{staticClass:"delete",on:{"click":function($event){return _vm.deleteI(item)}}},[_c('v-icon',{attrs:{"color":"#c10008"}},[_vm._v("mdi-delete")])],1)])])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }