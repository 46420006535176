<template>
  <v-container>
    <div class="wrap-pay-metod">
      <div
        class="wrap-metodo"
        v-for="(item, index) in paymentMetods"
        :key="index"
        @click="setType(item)"
        :class="{ disabled: item.active == false }"
      >
        <div class="img" :style="`background-color: ${item.color}; `">
          <img width="70%" :src="item.image" alt="" />
        </div>
        <div class="text-btn">
          <p>
            {{ item.paymentType == "card" ? "Crédito/Débito" : item.name }}
          </p>
          <div
            :class="{
              check: true,
              active: item == activeMetodos,
            }"
          >
            <transition name="bounce">
              <v-icon color="#fff" small v-if="item == activeMetodos">
                fa-solid fa-check-bold
              </v-icon>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="dialogTC"
      v-if="dialogTC"
      transition="dialog-bottom-transition"
      max-width="500"
      scrollable
      persistent
    >
      <v-card class="border-radius-class">
        <v-card-title>
          <div class="business">
            <b class="text-left font-weight-black subtitle-2">{{
              infoData.shortName
            }}</b>
          </div>

          <v-btn
            style="right: 10px; top: 10px"
            color="primary"
            fab
            small
            absolute
            @click="dialogTC = false"
          >
            <v-icon dark color="#fff">mdi-closes</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div class="card-icons">
                <span style="font-size: 18px" class="font-weight-bold"
                  >Información de la Tarjeta</span
                >
              </div>

              <v-row>
                <v-col class="mt-0" cols="12" sm="12" md="12">
                  <span class="ml-3 flex-betewn label"
                    >Nombre en la tarjeta
                    <div class="conexion-segura">
                      <span class="font-weight-light">Conexión Segura</span>
                      <img src="@/assets/icon/lock.svg" alt="" srcset="" /></div
                  ></span>
                  <v-text-field
                    class="mt-3"
                    placeholder="Nombre que aparece en la tarjeta"
                    filled
                    style="border-radius: 10px"
                    :class="{
                      'error-validation': validated && !card.name,
                    }"
                    :loading="loading"
                    hide-details
                    v-model="card.name"
                    rounded
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <span class="ml-3 label">Número de la tarjeta</span>
                  <v-text-field
                    style="border-radius: 10px"
                    filled
                    maxLength="20"
                    hide-details
                    @keydown="isNumber($event)"
                    prepend-inner-icon="fa fa-credit-card-front"
                    v-model="cardNumber"
                    type="text"
                    dence
                    :class="{
                      'error-validation': validated && !card.number,
                    }"
                    :loading="loading"
                    rounded
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <span class="ml-3 label">MM/AA</span>
                  <v-text-field
                    style="border-radius: 10px"
                    :loading="loading"
                    v-model="expiresAt"
                    hide-details
                    maxlength="5"
                    @keypress="maxLength($event, expiresAt, 5)"
                    :class="{
                      'error-validation': validated && !card.expiresAt,
                    }"
                    filled
                    rounded
                    placeholder="MM/AA"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <span class="ml-3 label">CVV</span>
                  <v-text-field
                    style="border-radius: 10px"
                    maxlength="3"
                    v-model="card.cvv"
                    @keydown="isNumber($event)"
                    @keypress="maxLength($event, card.cvv, 3)"
                    filled
                    rounded
                    prepend-inner-icon="fa fa-unlock"
                    hide-details
                    :class="{
                      'error-validation': validated && !card.cvv,
                    }"
                    :loading="loading"
                    placeholder="###"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <p class="px-5 mt-4 text-caption">
                    La información de tu tarjeta no será almacenada de ninguna
                    manera y servirá únicamente para realizar el pago actual.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-btn
            :loading="loading"
            block
            style="
              border-radius: 15px;
              box-shadow: 0px 4px 10px 5px rgba(255, 89, 0, 0.2);
            "
            @click="pay"
            class="btn-pay"
            color="primary"
            height="50px"
          >
            <!-- @click="pay" -->
            Pagar el monto de
            <span class="amount">{{ amount | currency }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      v-if="confirmDialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="550"
      scrollable
      style="border-radius: 10px"
    >
      <confirm
        :card="card"
        @success="next"
        :loading="loading"
        :link="link"
        :amount="amount"
        @cancel="confirmDialog = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import confirm from "@/components/confirm";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { emit } from "process";
export default {
  name: "oPay",
  components: {
    confirm,
  },
  props: ["businessId", "amount","dialogTC"],

  data() {
    return {
      activeMetodos: null,
      paymentMetods: [],
      link: {},
      validated: false,
      loading: false,
      confirmDialog: false,
      _publicKey: process.env.VUE_APP_PUBLIC_KEY,
      _privateKey: process.env.VUE_APP_PRIVATE_KEY,
      cash: {
        active: true,
        deleted: false,
        image: require("@/assets/cash.png"),
        name: "Efectivo",
        paymentType: "cash",
        displayName: "Efectivo",
        color: "#00cdbc",
        id: "",
      },
      card: {
        number: "",
        expiresAt: "",
        cvv: "",
      },
    };
  },
  computed: {
    ...mapGetters(["infoData"]),

    styleObject() {
      return {
        top: `-${this.top}px!important`,
      };
    },

    paymentMethodFilter() {
      if (this.link.paymentGateways.length > 1) {
        var filter = this.metodos.filter((item) =>
          this.link.paymentGateways.includes(item[".key"])
        );
        return filter;
      } else {
        return this.metodos;
      }
    },

    sortedmethods() {
      this.paymentMethodFilter.forEach((element) => {
        var el = element.paymentType;
        if (el == "dilo") {
          element.position = 1;
        }
        if (el == "tengo") {
          element.position = 2;
        }
        if (el == "tigoMoney") {
          element.position = 3;
        }
        if (el == "card") {
          element.position = 4;
        }
      });
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      let filtered = this.paymentMethodFilter.sort(compare);

      return filtered.filter(
        (e) =>
          !this.link.paymentGateways ||
          !this.link.paymentGateways.length ||
          this.link.paymentGateways.includes(e[".key"])
      );
    },
    cardNumber: {
      get() {
        let string = "";
        for (let i = 0; i < this.card.number.length; i = i + 4) {
          string = `${string} ${this.card.number.substring(i, i + 4)}`;
        }
        return string;
      },
      set(data) {
        this.card.number = data.replaceAll(" ", "");
      },
    },

    expiresAt: {
      get() {
        let string = "";
        for (let i = 0; i < this.card.expiresAt.length; i = i + 2) {
          string = `${string}${
            string ? "/" : ""
          }${this.card.expiresAt.substring(i, i + 2)}`;
        }
        return string;
      },
      set(data) {
        this.card.expiresAt = data.replaceAll("/", "");
      },
    },

    creditCardType() {
      let amex = new RegExp("^3[47][0-9]{13}$");
      let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
      let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
      let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

      let mastercard = new RegExp("^5[1-5][0-9]{14}$");
      let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

      let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
      let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
      let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

      let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
      let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

      if (visa.test(this.card.number)) {
        return "VISA";
      }
      if (amex.test(this.card.number)) {
        return "AMEX";
      }
      if (
        mastercard.test(this.card.number) ||
        mastercard2.test(this.card.number)
      ) {
        return "MASTERCARD";
      }
      if (
        disco1.test(this.card.number) ||
        disco2.test(this.card.number) ||
        disco3.test(this.card.number)
      ) {
        return "DISCOVER";
      }
      if (diners.test(this.card.number)) {
        return "DINERS";
      }
      if (jcb.test(this.card.number)) {
        return "JCB";
      }
      if (cup1.test(this.card.number) || cup2.test(this.card.number)) {
        return "CHINA_UNION_PAY";
      }
      return undefined;
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  methods: {
    async pay() {
      let validation = await this.validate();
      if (validation) {
        this.confirmDialog = true;
      }
    },
    RSAencrypt(d) {
      let jse = new JSEncrypt();
      let publicKey = process.env.VUE_APP_PUBLIC_KEY;

      jse.setPublicKey(publicKey);
      return JSON.stringify(d)
        .match(/.{1,100}/g)
        .map((part) => jse.encrypt(part));
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    maxLength(evt, field, length) {
      if (field.length >= length) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setType(item) {
      // type.paymentType

      // var typePaymentMethod = {
      //   card: () => {
         
      //   },
      //   dilo: () => {},
      //   tigoMoney: () => {},
      //   tengo: () => {},
      //   cash: () => {},
      // };
      // typePaymentMethod[item.paymentType]();
      this.$emit("getTypePayment", item.paymentType);
      this.activeMetodos = item;
    },
    validate() {
      return new Promise((resolve) => {
        let validCards = ["VISA", "AMEX", "MASTERCARD"];
        let monthsAllowed = [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ];

        let currentYear = new Date().getFullYear();
        currentYear = currentYear.toString().slice(2, 4);

        this.validated = true;
        if (!this.card.name) {
          this.snackbarText =
            "Debe ingresar el nombre que aparece en la tarjeta.";
          this.snackbar = true;
          return resolve(false);
        }

        if (!this.card.number || !validCards.includes(this.creditCardType)) {
          this.snackbarText = "Ingrese un número de tarjeta válido";
          this.snackbar = true;
          return resolve(false);
        }

        if (!this.card.expiresAt) {
          this.snackbarText = "Ingrese la fecha de vencimiento";
          this.snackbar = true;
          return resolve(false);
        }

        let year = this.card.expiresAt.slice(2, 4);
        let month = this.card.expiresAt.slice(0, 2);

        if (year < currentYear || !monthsAllowed.includes(month)) {
          this.snackbarText = "Ingrese una fecha de vencimiento válida";
          this.snackbar = true;
          return resolve(false);
        }

        if (!this.card.cvv) {
          this.snackbarText = "Ingrese el CVV de la tarjeta";
          this.snackbar = true;
          return resolve(false);
        }

        if (this.card.cvv.length != 3) {
          this.snackbarText = "El CVV debe ser de 3 dígitos.";
          this.snackbar = true;
          return resolve(false);
        }

        return resolve(true);
      });
    },
  },
  watch: {
    "card.number"(e) {
      let max = 20;
      if (e.length > max) {
        setTimeout(() => {
          this.card.number = e.substring(0, max);
        }, 100);
      } else if (e.length < max && isNaN(e.slice(-1))) {
        setTimeout(() => {
          this.card.number = this.card.number.substring(
            0,
            this.card.number.length - 1
          );
        }, 100);
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      var data = {
        businessId: this.businessId,
      };
      await axios
        .post(
          `${process.env.VUE_APP_EL_OCHO}/httpMenuhnGetPaymentMethods`,
          data
        )
        .then((response) => {
          this.paymentMetods = response.data.paymentGateways;
         
          // console.debug(response.data);
        })
        .catch((error) => {
          // console.debug(error);
        });
        this.paymentMetods.push(this.cash);
    });

    // https://us-central1-el-ocho-dev.cloudfunctions.net/httpMenuhnGetPaymentMethods
    // paymentOPay
  },
};
</script>
<style lang="scss">
.wrap-pay-metod {
  width: 100%;

  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  .wrap-metodo {
    width: 110px;
    height: 110px;
    margin: auto;
    background-color: #f2f2f2;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;
    .text-btn {
      padding: 5px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        font-size: 10px;
        margin: 0px;
      }
      .check {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 25px;
        height: 25px;
        border-radius: 10px;
        color: white;
        background-color: #e3e3e3;
        transition: 0.2s ease-in-out;
      }
      .active {
        background-color: #00cdbc !important;
      }
    }
    .img {
      position: absolute;
      height: 62px;
      width: 100%;
      background-size: cover;
      overflow: hidden;
      border-radius: 10px;
      background-color: #ff0048;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.wrap-pay-metod {
  width: 100%;

  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  .wrap-metodo {
    width: 110px;
    height: 110px;
    margin: auto;
    background-color: #f2f2f2;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;
    .text-btn {
      padding: 5px;
      width: 100%;
      position: absolute;
      bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        font-size: 10px;
        margin: 0px;
      }
      .check {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 25px;
        height: 25px;
        border-radius: 10px;
        color: white;
        background-color: #e3e3e3;
        transition: 0.2s ease-in-out;
      }
      .active {
        background-color: #00cdbc !important;
      }
    }
    .img {
      position: absolute;
      height: 62px;
      width: 100%;
      background-size: cover;
      overflow: hidden;
      border-radius: 10px;
      background-color: #ff0048;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.flex-betewn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.conexion-segura {
  height: 33px;
  padding: 5px;
  background-color: #f2f2f2;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px !important;

  span {
    color: #ff5900;
    font-size: 12px !important;
  }
  img {
    width: 15px;
  }
}
.label {
  font-size: 12px;
}
#threedsIframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>