<template>
  <div class="w-wrap-shopping">
    <div :class="{ 'wrap-content-shop': !infoData.qrIsFather }">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col
            cols="12"
            md="12"
            lg="6"
            sm="12"
            xl="6"
            v-if="orders.length > 0"
          >
            <CardTemplate
              titulo="Orden"
              :color="color"
              class="Orden_wrap"
              required="true"
            >
              <ItemListProduct
                :orders="orders"
                :color="color"
                @editI="btn_Edit"
                @deleteItem="btn_delete"
              />

              <div class="total-order mt-0">
                <h4 class="font-weight-light">
                  Total de productos :
                  <b class="font-weight-bold" :style="`color:${color}`">
                    {{ total | currency }}</b
                  >
                </h4>
              </div>
            </CardTemplate>
          </v-col>
          <v-col cols="12" md="12" lg="6" sm="12" xl="6" ref="Cliente">
            <CardTemplate titulo="Cliente">
              <v-container>
                <v-row class="mt-2">
                  <v-col class="my-0 pa-0" cols="12">
                    <v-text-field
                      :color="color"
                      v-model="client.name"
                      :rules="RequiredRule"
                      required
                      label="Nombre completo"
                      :readonly="isVipUser"
                      class="rounded-lg"
                      filled
                      @keydown="isString($event)"
                      rounded
                      :disabled="btnLoading"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-2">
                  <v-col class="my-0 pa-0 pr-2" cols="3">
                    <div class="wrap-flag">
                      <v-menu
                        offset-y
                        transition="slide-x-transition"
                        left
                        class=""
                        bottom
                        rounded="lg"
                        right
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div class="flag rounded-lg" v-bind="attrs" v-on="on">
                            <img
                              :src="`${require(`@/Countries/` + Country.flag)}`"
                              alt=""
                              srcset=""
                            />

                            <v-icon>mdi-menu-down</v-icon>
                          </div>
                        </template>
                        <v-list height="250px" class="list">
                          <v-list-item
                            v-for="(item, index) in Countries"
                            :key="index"
                            class="list-item"
                            @click="Country = item"
                          >
                            <div class="img-text">
                              <img
                                :src="`${require(`@/Countries/` + item.flag)}`"
                                alt=""
                                srcset=""
                              />
                              <p class="ml-2">
                                <b>{{ item.name }} </b> +{{
                                  item.callingCodes[0]
                                }}
                              </p>
                            </div>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col class="my-0 pa-0" cols="9">
                    <v-text-field
                      :color="color"
                      v-model="phone"
                      height="52px"
                      required
                      :readonly="isVipUser"
                      @keydown="isNumber($event)"
                      type="tel"
                      :rules="client.phone == '' ? [] : phoneRule"
                      messages="Para recibir SMS de tu orden"
                      class="rounded-lg"
                      filled
                      :disabled="btnLoading"
                      rounded
                      :prefix="`+${Country.callingCodes[0]}`"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="my-0 pa-0">
                    <v-text-field
                      v-model="client.email"
                      :rules="client.email == '' ? [] : emailRules"
                      :color="color"
                      label="Correo"
                      type="email"
                      messages="Opcional ( Recibir tu comprobante de tu compra )"
                      class="rounded-lg"
                      filled
                      :disabled="btnLoading"
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="mt-3 pa-0">
                    <!-- <v-checkbox
                      v-model="requiredTin"
                      label="¿Factura con nombre?"
                    ></v-checkbox> -->
                    <v-btn
                      block
                      :color="color"
                      dark
                      style="border-radius: 10px"
                      elevation="0"
                      :outlined="validTin"
                      @click="dialogTin = true"
                    >
                      {{
                        client.tinName
                          ? `${client.tinName} - ${client.tin}`
                          : "¿Factura con nombre?"
                      }}
                    </v-btn>
                    <p class="ml-3" style="color: #6e6e6e; font-size: 0.8em">
                      Factura con nombre
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </CardTemplate>
            <CardTemplate
              titulo="Tipo de entrega"
              :required="!typeActive"
              label="Obligatorio"
              :color="color"
            >
              <v-row
                class="my-2"
                align-content="center"
                justify="center"
                ref="typeOrderForm"
              >
                <v-col
                  v-for="(item, index) in allTypeOrder"
                  :key="index"
                  cols="12"
                  justify-center
                >
                  <div
                    :class="{
                      'btn-type-order': true,
                    }"
                    :color="color"
                    @click="typeActive = item"
                  >
                    <img :src="typeOrderSetFilter(item).icon" height="40px" />
                    <b class="text-caption">{{
                      typeOrderSetFilter(item).text
                    }}</b>
                    <div class="wrap-btn-actions">
                      <div class="btn-act">
                        <transition name="bounce">
                          <div
                            v-if="typeActive == item"
                            class="active-btn"
                          ></div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div
                class="wrap-dinam-inpt"
                v-if="typeActive"
                :style="`border: solid ${color} 2px;`"
                ref="typeOrderEntrega"
              >
                <v-row>
                  <v-col cols="12" v-if="typeActive == 'regular'">
                    <Mapa
                      height="35vh"
                      :isModePay="modePay"
                      typeActive
                      :color="color"
                      :draggableActive="false"
                      :expandBtn="true"
                      :search="false"
                      :locationUpdate="locationUserDelivery"
                      @cost="costDeliveryFn"
                      @Expand="expand_modal"
                      @updated="updateLocations"
                      @valuePoint="valuePointFn"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                  v-if="typeActive == 'dineIn' || typeActive == 'room'"
                  >
                    <v-text-field
                      v-if="typeActive == 'dineIn'"
                      :color="color"
                      v-model="tableValue"
                      height="52px"
                      :rules="RequiredRule"
                      required
                      type="text"
                      class="rounded-lg"
                      filled
                      label="Ingresa código de la mesa"
                      onkeyup="javascript:this.value=this.value.toUpperCase();"
                      :disabled="btnLoading"
                      rounded
                    >
                    </v-text-field>
                    <v-text-field
                      v-if="typeActive == 'room'"
                      :color="color"
                      v-model="room"
                      height="52px"
                      :rules="RequiredRule"
                      required
                      type="text"
                      class="rounded-lg"
                      filled
                      label="Ingresa código de la habitación"
                      onkeyup="javascript:this.value=this.value.toUpperCase();"
                      :disabled="btnLoading"
                      rounded
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="typeActive == 'regular' || typeActive == 'pickUp'"
                  >
                    <v-text-field
                      v-if="typeActive == 'pickUp'"
                      :color="color"
                      height="52px"
                      required
                      v-model="pickUpTime"
                      :rules="RequiredRule"
                      class="rounded-lg"
                      filled
                      :value="pickUpTime"
                      label="Hora en la que pasaras por tu orden"
                      type="time"
                      :disabled="btnLoading"
                      rounded
                    >
                    </v-text-field>
                    <v-textarea
                      filled
                      auto-grow
                      :color="color"
                      class="rounded-lg"
                      rounded
                      :rules="typeActive == 'pickUp' ? [] : RequiredRule"
                      v-model="comentario"
                      required
                      placeholder=""
                      :label="labelType"
                      rows="1"
                      row-height="100"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
              <div>
                <div class="total">
                  <div
                    class="list-sub-price mb-6"
                    v-if="infoData.allowTip && total"
                  >
                    <p><b>Propina</b></p>

                    <div class="display-flex">
                      <v-btn
                        height="35px"
                        width="35px"
                        fab
                        class="ml-2"
                        @click="btnProp(btnPropina.cero)"
                        :style="`color: ${
                          btnPropina.cero == btnActi ? '#fff' : '#000'
                        }`"
                        :color="btnPropina.cero == btnActi ? color : ''"
                      >
                        0
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        height="35px"
                        width="35px"
                        class="ml-2"
                        @click="btnProp(btnPropina.percentage)"
                        :style="`color: ${
                          btnPropina.percentage == btnActi ? '#fff' : '#000'
                        }`"
                        :color="btnPropina.percentage == btnActi ? color : ''"
                      >
                        {{ infoData.qrTipAmount }}%
                      </v-btn>
                      <v-btn
                        small
                        height="35px"
                        width="70px"
                        rounded
                        elevation="8"
                        class="ml-2"
                        @click="btnProp(btnPropina.otro)"
                        :style="`color: ${
                          btnPropina.otro == btnActi ? '#fff' : '#000'
                        }`"
                        :color="btnPropina.otro == btnActi ? color : ''"
                      >
                        Otro
                      </v-btn>
                    </div>
                  </div>
                  <div class="list-sub-price">
                    <p>Subtotal</p>
                    <strong>
                      {{ total | currency }}
                    </strong>
                  </div>
                  <div class="list-sub-price" v-if="infoData.allowTip && total">
                    <p>Propina</p>
                    <strong>
                      {{
                        propinaCustom != null
                          ? propinaCustom
                          : qrTipResul | currency
                      }}
                    </strong>
                  </div>
                  <div class="list-sub-price" v-if="typeActive == 'regular'">
                    <p>Envío</p>
                    <strong>
                      {{ costDelivery | currency }}
                    </strong>
                  </div>
                  <div class="list-sub-price">
                    <p>Total de la orden</p>
                    <h2 :style="`color:${color}`">
                      <b> {{ getTotal | currency }}</b>
                    </h2>
                  </div>
                </div>
                <v-row class="mt-6">
                  <v-col cols="12" class="flex-center">
                    <h4>Seleccionar Método de Pago</h4>
                  </v-col>
                </v-row>

                <v-row v-if="!dialogPaymentOnline">
                  <v-col
                    class="btn-compra"
                    cols="12"
                    sm="12"
                    md="12"
                    :lg="infoData.qrBtnCash ? '6' : '12'"
                    v-if="infoData.qrBtnOnline"
                  >
                    <v-btn
                      :color="color"
                      width="100%"
                      height="50px"
                      :loading="btnLoading"
                      :disabled="!disbledBtn"
                      class="rounded-lg color-white"
                      @click="GenarateOrder('online')"
                      elevation="0"
                    >
                      <b>Pagar en Línea</b>
                    </v-btn>
                  </v-col>
                  <v-col
                    class="btn-compra"
                    cols="12"
                    sm="12"
                    md="12"
                    :lg="infoData.qrBtnOnline ? '6' : '12'"
                    v-if="infoData.qrBtnCash"
                  >
                    <v-btn
                      :color="color"
                      width="100%"
                      elevation="0"
                      height="50px"
                      :disabled="!disbledBtn"
                      outlined
                      @click="validCash"
                      class="rounded-lg"
                      :loading="btnLoading"
                      >Efectivo
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </CardTemplate>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-dialog
      v-model="dialogEfectivo"
      v-if="dialogEfectivo"
      width="550"
      persistent
      scrollable
      class="modal-cash"
    >
      <v-card min-height="300px" class="modal-cash">
        <v-card-title center class="text-h6 titulo-cash text-center">
          Cambio para pago en efectivo
          <v-btn
            class="btn btn-closed-cash"
            @click="(dialogEfectivo = false), (exchange = null)"
            absolute
            icon
            right
            top
            large
          >
            <v-icon color="#000"> mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          Recuerda que el valor de tu orden <br />
          es de
          <b :style="`color:${color}`">{{ getTotal | currency }}</b>

          <v-row class="pa-4">
            <v-col cols="12">
              <v-text-field
                :color="color"
                v-model="exchange"
                height="52px"
                @keydown="isNumber($event)"
                type="tel"
                required
                name="exchange"
                :rules="flag_cambio ? RequiredRule : []"
                label="Necesito cambio para"
                class="rounded-lg"
                hide-details
                filled
                onkeyup="javascript:this.value=this.value.toUpperCase();"
                :disabled="btnLoading"
                rounded
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="mb-2">
          <v-row>
            <v-col cols="12" md="6" sm="6" v-if="typeActive != 'regular'">
              <v-btn
                :color="color"
                width="100%"
                elevation="0"
                height="50px"
                dark
                @click="GenarateOrder('cash')"
                class="rounded-lg"
                :loading="btnLoading"
                >no necesito cambio
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              :md="typeActive != 'regular' ? '6' : '12'"
              :sm="typeActive != 'regular' ? '6' : '12'"
            >
              <v-btn
                :color="color"
                width="100%"
                elevation="0"
                height="50px"
                dark
                @click="(flag_cambio = true), GenarateOrder('cash')"
                class="rounded-lg"
                :loading="btnLoading"
                >continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="otroPropina"
      v-if="otroPropina"
      max-width="400"
      persistent
    >
      <v-card style="border-radius: 15px !important">
        <v-card-title center class="text-h6 text-center">
          <v-btn
            @click="(otroPropina = false), (propina = null)"
            absolute
            icon
            right
            top
            small
          >
            <v-icon color="#000"> mdi-close</v-icon>
          </v-btn>
          Ingresar monto
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4">
          <v-text-field
            :color="color"
            v-model="propinaCustom"
            height="52px"
            @keydown="isNumber($event)"
            type="tel"
            required
            hide-details
            placeholder="Ingresar la cantidad de la propina"
            class="rounded-lg"
            filled
            :disabled="btnLoading"
            rounded
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="flex-center">
          <v-btn
            :color="color"
            width="100%"
            elevation="0"
            height="50px"
            block
            dark
            @click="otroPropina = false"
            class="rounded-lg my-2"
            :loading="btnLoading"
            >continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogEdit
      v-if="modalEdit"
      :dialog="modalEdit"
      :data="DataEdit"
      :color="color"
      @closed="dialogClose"
    />
    <v-dialog v-if="deliveryMap" max-width="1000" v-model="deliveryMap">
      <v-card
        min-height="80vh"
        style="position: relative; border-radius: 15px !important"
        class="display-center"
      >
        <v-btn
          class="btn-closed-map"
          @click="deliveryMap = false"
          absolute
          right
          fab
          dark
          small
          elevation="1"
          style="border-radius: 15px !important"
          :color="color"
        >
          <v-icon dark> mdi-close</v-icon>
        </v-btn>

        <Mapa
          height="80vh"
          :locationUpdate="locationUserDelivery"
          :draggableActive="true"
          :search="true"
          :isModePay="modePay"
          :color="color"
          :expandBtn="false"
          @cost="costDeliveryFn"
          @updated="updateLocations"
          @valuePoint="valuePointFn"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="dialogDeleted"
      v-model="dialogDeleted"
      persistent
      max-width="500px"
    >
      <Dialog :data="dataDialog" :color="color" @close="closed_item" />
    </v-dialog>
    <v-dialog v-model="dialogOtp" v-if="dialogOtp" max-width="300px" persistent>
      <v-card class="pa-2" style="border-radius: 15px !important">
        <!-- <v-btn class="btn-closed-map" @click="deliveryMap = false" absolute right fab small elevation="1"
          :color="color">
          <v-icon color="#fff"> mdi-close</v-icon>
        </v-btn> -->
        <v-card-title>📟 Ingresa el código</v-card-title>
        <v-card-subtitle>Enviado por SMS </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <div class="ma-auto position-relative" style="max-width: 300px">
            <v-otp-input
              v-model="codeOtp"
              :color="color"
              length="6"
              :disabled="loading"
              type="tel"
              class="rounded-lg mt-6"
              filled
              rounded
              @keydown="isNumber($event)"
              @finish="onFinish"
            ></v-otp-input>
            <v-overlay absolute :value="loading">
              <v-progress-circular
                indeterminate
                :color="color"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </v-card-text>
        <!-- <v-divider></v-divider> -->
        <!-- <v-card-actions justify-end>
          <v-btn block height="50px" dark :color="color" style="border-radius: 15px;">
            Aceptar
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTin" v-if="dialogTin" max-width="400px" persistent>
      <v-card color="pa-2" style="border-radius: 15px !important">
        <v-card-title>
          Factura con nombre

          <v-btn @click="dialogTin = false" absolute icon right top large>
            <v-icon color="#000"> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <label for="">Nombre de factura</label>
          <v-text-field
            :color="color"
            v-model="client.tinName"
            height="52px"
            type="text"
            required
            hide-details
            @keydown="isString($event)"
            placeholder="Nombre de factura"
            class="rounded-lg mb-4"
            filled
            rounded
          ></v-text-field>
          <label for="">Número fiscal</label>
          <v-text-field
            :color="color"
            v-model="client.tin"
            height="52px"
            @keydown="isNumber($event)"
            type="tel"
            required
            hide-details
            placeholder="Número fiscal"
            class="rounded-lg"
            filled
            rounded
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            height="45px"
            class="rounded-lg"
            block
            dark
            :color="color"
            @click="validTinFn()"
          >
            Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      style="border-radius: 10px !important"
      v-model="dialogPaymentOnline"
      v-if="dialogPaymentOnline"
      max-width="500px"
      hide-overlay
      :fullscreen="mobile"
      scrollable
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card color="#fff">
        <v-toolbar dark color="primary">
          <v-btn @click="cancelPayOnline" absolute fab small right dark>
            <v-icon color="#fff"> mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <img src="@/assets/logoPay.svg" height="30px" alt="" srcset="" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pa-0">
          <!-- sandbox="allow-scripts allow-same-origin allow-forms allow-popups  " -->
          <iframe
            class="iframe-pay"
            :src="`${dataIframe}?radius=10&btncolor=${color.slice(
              1
            )}&colorprimary=${color.slice(1)}`"
            frameborder="0"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as Vacio from "@/assets/Lottie/shoping_Vacio.json";
import { mapActions, mapGetters } from "vuex";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import countries from "../Countries/countries.json";
import country from "../Countries/country.json";
import Header from "@/components/header";
import CardTemplate from "@/components/cardTemplate";
import ItemListProduct from "@/components/itemListProduct";
import axios from "axios";
import Dialog from "@/components/dialog";
import headerShop from "@/components/headerShopBusiness";
import ProductList from "@/components/productList";
import DialogEdit from "@/components/dialogEdit";
import DialogSuccess from "@/components/DialogSuccess";
import oPay from "@/components/oPay";
import Mapa from "@/components/map-picker";
import store from "@/store/index.js";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "ShoppingCart",
  components: {
    lottie: Lottie,
    Header,
    VueTelInputVuetify,
    CardTemplate,
    headerShop,
    ProductList,
    ItemListProduct,
    DialogEdit,
    Dialog,
    Mapa,
    DialogSuccess,
    oPay,
  },
  filters: {
    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  data() {
    return {
      color: "",
      validTin: false,
      dialogTin: false,
      requiredTin: false,
      modePay: false,
      dialogPaymentOnline: false,
      pickUpTime: null,
      codeOtp: null,
      allTypeOrder: [],
      deliveryMap: false,
      dialogEfectivo: false,
      successOrder: false,
      totalDelivery: null,
      otroPropina: false,
      propinaCustom: null,
      deliveryZoneValue: null,
      latitude: 14.0625764,
      longitude: -87.2226859,
      costDelivery: 0,
      btnActi: "%",
      btnPropina: {
        cero: "0",
        percentage: "%",
        otro: "otro",
      },
      openDialogTC: false,
      tables: [],
      typeActive: null,
      exchange: null,
      typePaymentMethod: null,
      orderResponse: null,
      Countries: countries,
      Country: country,
      btnLoading: false,
      tableValue: "",
      room: null,
      phone: null,
      client: {
        tin: "",
        tinName: "",
        name: null,
        phone: null,

        email: "",
        table: "",
      },
      mobile: screen.width <= 550 ? true : false,
      loading: false,
      dialogOtp: false,
      DataEdit: {},
      modalEdit: false,
      disbledBtn: true,
      alert: false,
      list_flag: false,
      dataDialog: {},
      total: 0,
      total_final: 0,
      flag_cambio: false,
      valid: true,
      firstname: "",
      lastname: "",
      dialogDeleted: false,
      comentario: "",
      selectedItem: null,
      orders: [],
      locationUserDelivery: null,
      animationSpeed: 2,
      defaultOptions: {
        animationData: Vacio.default,
        loop: true,
        autoplay: true,
        lenguaje: "",
      },
      isVipUser: false,
      pendingTransactions: [],
      RequiredRule: [(v) => !!v || "Requerido"],
      phoneRule: [
        (v) => /^[0-9]+$/.test(v) || "Campo numérico",
        (v) => !!v || "Requerido",
      ],
      emailRules: [(v) => /.+@.+/.test(v) || "Correo no valido"],
      typeActiveRule: [(v) => !!v || "Requerido"],
    };
  },
  async mounted() {
    window.scrollTo(0, 0);

    if (this.dataClientOrder.userName && this.dataClientOrder.userPhone) {
      this.client.name = this.dataClientOrder.userName;
      this.phone = Number(this.dataClientOrder.userPhone);
      this.isVipUser = true;
    }

    if (this.infoData.id) {
      this.Add_pathQr(this.$route.params._id);
      this.color = this.infoData.qrThemeColor;
      this.allTypeOrder = this.infoData.qrTypesOrders;
    } else {
      this.Add_pathQr(this.$route.params._id);
    }
    this.orders = Object.assign([], this.CarShop);
    this.calcular_total();
    await this.getTables();
  },
  methods: {
    ...mapActions([
      "AddInfoClient",
      "CleanData",
      "Alert_",
      "remove",
      "Add_pathQr",
      "limpiar_Product",
      "loader_Process",
      "orderResponse_",
    ]),
    validCash() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.dialogEfectivo = true;
      } else {
        this.sendAlert(
          "😬 Asegurate de completa tu información y seleccionar un método de entrega y de pago",
          "error"
        );
      }
    },
    cancelPayOnline() {
      this.dialogPaymentOnline = false;
      this.loading = false;
      this.limpiar_Product("ok");
      this.disbledBtn = true;
      this.btnLoading = false;
    },
    nextOrderValidate() {
      this.$refs.form.validate();
      if (
        this.$refs.form.validate() &&
        this.typeActive &&
        this.typePaymentMethod
      ) {
        var typePaymentMethod = {
          card: () => {
            this.openDialogTC = true;
          },
          dilo: () => {},
          tigoMoney: () => {
            this.GenarateOrder();
          },
          tengo: () => {
            this.GenarateOrder();
          },
          cash: () => {
            this.dialogEfectivo = true;
          },
        };
        typePaymentMethod[this.typePaymentMethod]();
      } else {
        this.sendAlert(
          "😬 Asegurate de completa tu información y seleccionar un método de entrega y de pago",
          "error"
        );
      }
    },
    getTypePaymentFN(type) {
      // console.debug(type);
      this.typePaymentMethod = type;
    },
    typeOrderSetFilter(type) {
      const typeOrder = {
        dineIn: {
          text: "Dine in (Ordenar en mesa)",
          icon: require("@/assets/finalIconsdineIn.svg"),
        },
        pickUp: {
          text: "Pickup (Recoger en tienda)",
          icon: require("@/assets/finalIconstakeout.svg"),
        },
        regular: {
          text: "Delivery (A domicilio)",
          icon: require("@/assets/finalIconsdelivery.svg"),
        },
        room: {
          text: "Room Services (A la habitación)",
          icon: require("@/assets/finalIconsroomService.svg"),
        },
      };
      return typeOrder[type];
    },
    onFinish(rsp) {
      this.loading = true;
      this.disbledBtn = false;
      // console.debug(rsp);
      this.btnLoading = true;
      setTimeout(() => {
        this.dialogOtp = true;
        this.btnLoading = false;
        this.dialogEfectivo = false;
        this.nextOrder();
      }, 2000);
    },
    expand_modal(e) {
      this.deliveryMap = true;
    },
    updateLocations(locationUser) {
      this.locationUserDelivery = locationUser;
    },
    costDeliveryFn(cost) {
      // console.debug(cost);
      this.costDelivery = cost;
      // // console.debug(this.costDelivery);
    },
    valuePointFn(vPoint) {
      this.disbledBtn = vPoint;

      if (vPoint) {
      } else {
        this.sendAlert(
          "Favor indica tu dirección dentro del área sombreada (color naranja). 🗺 📍",
          "error"
        );
      }
    },
    btnProp(e) {
      this.btnActi = e;

      switch (e) {
        case "0":
          this.propinaCustom = 0;
          break;
        case "%":
          this.propinaCustom = this.qrTipResul;
          break;
        case "otro":
          this.propinaCustom = null;
          this.otroPropina = true;
          break;

        default:
          break;
      }
    },

    getTables() {
      let tables = this.$binding(
        "tables",
        db
          .collection(`businesses/${this.infoData.id}/tables`)
          .where("active", "==", true)
          .where("deleted", "==", false)
      ).then((response) => {});
      return tables;
    },
    btn_delete(item) {
      this.dataDialog = item;
      this.dialogDeleted = true;
    },
    btn_Edit(item) {
      this.DataEdit = item;
      this.modalEdit = true;
    },

    async GenarateOrder(PaymentMethod) {
      this.typePaymentMethod = PaymentMethod;
      this.$refs.form.validate();
      var cliente;
      if (this.$refs.form.validate() && this.typeActive != null) {
        var code = this.Country.callingCodes[0];
        this.client.phone = code + this.phone;
        cliente = Object.assign({}, this.client);
        this.btnLoading = true;
        this.loader_Process(1);

        if (this.CarShop.length == 0) {
          this.sendAlert("😬 No tienes ninguna orden.", "error");
          this.btnLoading = false;
        } else {
          // this.AddInfoClient(cliente);
          if (this.infoData.qrRequiredOtp && this.infoData.qrIsVip) {
            let data = {
              businessId: this.infoData.id,
              phone: this.Country.callingCodes[0] + this.phone,
              provider: 1,
            };
            await axios
              .post(`${process.env.VUE_APP_EL_OCHO}/httpMenuhnSendOtp`, data)
              .then((response) => {
                // console.debug(response.data);
                this.dialogOtp = true;
              })
              .catch((error) => {
                // console.debug(error.response);
                this.sendAlert("Ocurrio un error inesperado 😬", "error");
                this.btnLoading = false;
                return;
              });
          } else {
            this.nextOrder();
          }
        }
      } else {
        if (this.client.name == null || this.phone == null) {
          this.scrollYm("Cliente");
          this.sendAlert("😬 llena la información del cliente", "error");
        } else if (this.typeActive == null) {
          this.scrollYm("typeOrderForm");
          this.sendAlert("😬 Selecciona un tipo de entrega.", "error");
        } else {
          this.scrollYm("typeOrderEntrega");
          this.sendAlert("😬 Completa tu información", "error");
        }
      }
    },
    closedSuccess() {
      this.CleanData();
      this.calcular_total();

      // this.$router.push({ name: "Home" });
    },
    getOffset(topAll) {
      var _x = 0;
      var _y = 0;
      while (topAll && !isNaN(topAll.offsetLeft) && !isNaN(topAll.offsetTop)) {
        _x += topAll.offsetLeft - topAll.scrollLeft;
        _y += topAll.offsetTop - topAll.scrollTop;
        topAll = topAll.offsetParent;
      }
      return { top: _y, left: _x };
    },
    scrollYm(item) {
      const ref = {
        Cliente: this.$refs.Cliente,
        typeOrderForm: this.$refs.typeOrderForm,
        typeOrderEntrega: this.$refs.typeOrderEntrega,
      };

      var topAll = ref[item];
      // console.debug(topAll);
      var y = this.getOffset(topAll).top;
      // console.debug(y);
      window.scroll({
        top: y + -100,
        left: 0,
        behavior: "smooth",
      });
    },
    closed_item(c, data) {
      this.dialogDeleted = false;

      if (c == true) {
        this.dialogDeleted = false;
        this.eliminar_items(data);
      }
    },
    eliminar_items(e) {
      this.remove(e);
      this.orders = [];
      this.orders = Object.assign([], this.CarShop);
      this.calcular_total();
      this.sendAlert("Producto eliminado", "success");
    },
    calcular_total() {
      this.total = 0;
      this.orders.forEach((element) => {
        this.total = this.total + element.priceDOM;
      });
    },
    RecorrerOrder(infoOrder, dataRes) {
      var info = infoOrder;
      var data = dataRes;
      return new Promise((resolve, reject) => {
        data.forEach((a) => {
          // console.debug("a", a);
          a.orderP.businessId = a.business[0];
          info.products.push(a.orderP);
        });
        return resolve(info);
      });
    },
    dialogClose(edit) {
      if (edit) {
        this.modalEdit = false;
        this.DialogEdit = {};
        this.orders = Object.assign([], this.CarShop);
      } else {
        this.modalEdit = false;
        this.DialogEdit = {};
      }
    },
    addPreorder() {
      return new Promise((resolve, reject) => {
        var dataRespal = Object.assign([], this.CarShop);

        for (let index = 0; index < dataRespal.length; index++) {
          var value = [];
          let i = dataRespal[index].addonsArray;

          i.forEach((add) => {
            if (add.controlType == "radio" && add.valueResp) {
              var item = {
                addonGroupId: add.id,
                addonId: add.valueResp.idDoc,
                productId: add.valueResp.productId,
                quantity: 1,
              };
              value.push(item);
            }
            if (add.controlType == "checkbox" && add.valueResp) {
              add.valueResp.forEach((valu) => {
                var item = {
                  addonGroupId: add.id,
                  addonId: valu.idDoc,
                  productId: valu.productId,
                  quantity: 1,
                };
                value.push(item);
                item = {};
              });
            }
            if (add.controlType == "spin" && add.valueResp) {
              const filtro = add.products.filter((i) => i.resul > 0);

              filtro.forEach((produ) => {
                var item = {
                  addonGroupId: add.id,
                  addonId: produ.idDoc,
                  productId: produ.productId,
                  quantity: produ.resul,
                };

                value.push(item);
                item = {};
              });
            }
          });

          dataRespal[index].orderP.addons = value;
          i = [];
        }
        console.debug(dataRespal);
        resolve(dataRespal);
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isString(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode != 32 && charCode < 65 && charCode >= 48) ||
        (charCode > 90 && charCode < 97) ||
        charCode > 122
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async nextOrder() {
      this.modePay = true;
      await this.addPreorder().then((response) => {
        if (this.exchange) {
          if (this.exchange >= this.total) {
          } else {
            this.sendAlert(
              "La cantidad es menor que el total de tu orden",
              "error"
            );
            this.btnLoading = false;
            return;
          }
        }
        var tinValue = {
          0: 0,
          "%": this.qrTipResul,
          otro: this.propinaCustom,
        };

        var dataRespaldo = response;
        var infoOrder = Object.assign({}, this.dataClientOrder);
        infoOrder.category = this.infoData.qrCategory;
        infoOrder.city = this.infoData.qrCity;
        infoOrder.type = this.typeActive;
        infoOrder.business = this.infoData.id;
        infoOrder.paymentMethod = this.typePaymentMethod;
        infoOrder.exchange = this.exchange;
        infoOrder.table = this.tableValue;
        infoOrder.userPhone = this.client.phone;
        infoOrder.userName = this.client.name;
        infoOrder.email = this.client.email;
        infoOrder.tin = this.client.tin;
        infoOrder.tinName = this.client.tinName;
        infoOrder.tip = parseInt(tinValue[this.btnActi]);
        infoOrder.instructions = this.comentario;

        if (this.typeActive == "regular") {
          infoOrder.userAddressLongitude = this.locationUserDelivery[1];
          infoOrder.userAddressLatitude = this.locationUserDelivery[0];
        }
        if (this.infoData.qrRequiredOtp) {
          infoOrder.otp = this.codeOtp;
        }
        if (this.typeActive == "pickUp") {
          infoOrder.pickUpTime = this.pickUpTime;
          infoOrder.instructions = `${
            this.client.name
          } llegara por su orden a las ${moment(
            this.pickUpTime,
            "HH:mm"
          ).format("hh:mm a")}\n- ${this.comentario}`;
        }
        if (this.typeActive == "room") {
          infoOrder.room = this.room;
        }

        this.RecorrerOrder(infoOrder, dataRespaldo).then((dataPost) => {
          // console.debug(dataPost);

          axios
            .post(
              `${process.env.VUE_APP_EL_OCHO}/httpGenerateQrOrder`,
              dataPost
            )
            .then((resp) => {
              this.loading = false;
              if (resp.status == 200) {
                if (this.typePaymentMethod == "online") {
                  this.dataIframe = resp.data.link;
                  this.dialogPaymentOnline = true;

                  //______________ Esto es ocho pay fuera de la pagina -----
                  // var win = window.open(this.dataIframe, "_blank");
                  // win.focus();
                  // window.location.href = this.dataIframe;
                  // this.closedSuccess();
                  //______________ Esto es ocho pay fuera de la pagina -----

                  if (resp.data.paymentReference) {
                    db.collection("pendingPaymentRequest")
                      .doc(resp.data.paymentReference)
                      .onSnapshot((doc) => {
                        if (doc.data().status == "paid") {
                          this.dataIframe = null;
                          this.dialogPaymentOnline = false;
                          this.orderResponse = doc.data().orders;
                          this.orderResponse_(this.orderResponse);
                          this.sendAlert(
                            "Orden colocada correctamente",
                            "success"
                          );
                          setTimeout(() => {
                            this.successOrder = true;
                            this.btnLoading = false;
                            this.disbledBtn = false;
                            this.modePay = false;
                            this.closedSuccess();
                          }, 1000);
                        }
                      });
                  }
                  this.dialogPaymentOnline = true;
                  this.btnLoading = false;
                  this.disbledBtn = false;
                  this.modePay = false;
                } else {
                  this.sendAlert(resp.data.message, "success");
                  this.orderResponse = resp.data.orders;
                  this.orderResponse_(this.orderResponse);

                  setTimeout(() => {
                    this.successOrder = true;
                    this.btnLoading = false;
                    this.disbledBtn = false;
                    this.modePay = false;
                    this.closedSuccess();
                  }, 1000);
                }
              }
            })
            .catch((error) => {
              this.limpiar_Product("ok");
              if (error.response.data && error.response.data.code == "010") {
                this.loading = false;
                this.sendAlert("😬 " + error.response.data.message, "error");
                this.codeOtp = null;
                this.dialogOtp = true;
                this.btnLoading = false;
                this.disbledBtn = true;
                this.modePay = false;
              } else if (error.request) {
                this.sendAlert(
                  "😥 Algo salio mal al procesar tu orden.",
                  "error"
                );
                this.btnLoading = false;
                this.disbledBtn = true;
                this.modePay = false;
              } else {
                this.sendAlert(
                  "😥 Algo salio mal al procesar tu orden.",
                  "error"
                );
                this.modePay = false;
                this.btnLoading = false;
                this.disbledBtn = true;
              }
            });
        });
      });
    },
    validTinFn() {
      if (this.client.tinName && this.client.tin) {
        this.dialogTin = false;
        this.validTin = true;
      } else {
        this.sendAlert("Llena la informació de la factura", "error");
      }
    },
    sendAlert(message, type) {
      this.Alert_({
        text: message,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-alert",
        type: type,
      });
    },
  },
  computed: {
    ...mapGetters(["CarShop", "cantOrder", "infoData", "dataClientOrder"]),
    getTime() {
      var today = new Date();

      // obtener la hora en la configuración regional de EE. UU.
      return today.toLocaleTimeString("en-US");
    },
    qrTipAmount() {
      return this.infoData.qrTipAmount;
    },
    qrExtraFee() {
      return this.infoData.qrExtraFee;
    },
    qrTipResul() {
      var porcentaje = (this.qrTipAmount / 100) * this.total;
      return porcentaje;
    },
    getTotal() {
      let propina =
        this.propinaCustom != null
          ? parseInt(this.propinaCustom) || 0
          : this.qrTipResul;

      if (this.infoData.allowTip && this.typeActive != "regular") {
        this.calcular_total();
        var t = this.total + propina;
        return t;
      }

      if (this.infoData.allowTip && this.typeActive == "regular") {
        this.calcular_total();
        this.totalDelivery = this.total + parseInt(this.costDelivery);
        var result = this.totalDelivery + propina;

        return result;
      }

      if (!this.infoData.allowTip && this.typeActive == "regular") {
        this.calcular_total();
        this.totalDelivery = this.total + parseInt(this.costDelivery);

        return this.totalDelivery;
      }

      if (!this.infoData.allowTip && this.typeActive != "regular") {
        this.calcular_total();
        return this.total;
      }
    },

    labelType() {
      switch (this.typeActive) {
        case "regular":
          return "Punto de referencia";
          break;
        case "pickUp":
          return "¿Algún comentario?";
        default:
          return "Comentario";
          break;
      }
    },
    // filter_qrDelvieryFees: function () {
    //   var selectFees = [];
    //   this.Zones.forEach((i) => {
    //     const obj = {
    //       text: i.name + " +" + i.cost,
    //       value: i,
    //     };
    //     selectFees.push(obj);
    //   });
    //   return selectFees;
    // },
    filter_Tables: function () {
      var selecttables = [];
      this.tables.forEach((i) => {
        const obj = {
          text: i.name,
          value: i[".key"],
        };
        selecttables.push(obj);
      });
      return selecttables;
    },
  },
  watch: {
    requiredTin(e) {
      this.dialogTin = e ? true : false;
    },
    tableValue(c) {},
    typeActive(value) {
      this.calcular_total();
      if (value == "regular") {
      } else {
        this.deliveryZoneValue = null;
        this.totalDelivery = null;
      }
    },
    CarShop(e) {
      if (e.length == 0) {
        this.$router.push({
          path: `/${this.$route.params._id}`,
        });
      }
      this.orders = e;
    },
    cantOrder(e) {
      this.orders = e;
    },
    infoData(data) {
      this.color = data.qrThemeColor;
    },
  },
};
</script>

<style lang="scss" >
.wrap-dinam-inpt {
  background-color: #f2f2f2;

  padding: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
}

@import "@/_responsive.scss";
@import "@/main.scss";

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.iframe-pay {
  width: 100% !important;
  height: 100vh;
}
</style>
