var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":_vm.mobile,"fullWidth":"true","transition":"dialog-bottom-transition","persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('div',{staticClass:"form",style:(`border-radius: ${_vm.mobile ? '0px':'15px'} !important`)},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"nav-back-closed"},[_c('v-btn',{staticClass:"btn",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$emit('closed', false)}}},[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v(" mdi-close")])],1)],1),_c('div',{staticClass:"modal-wrap-main"},[(_vm.dataEdit != null)?_c('v-card',{staticClass:"modal-modiu wrap-trs",attrs:{"elevation":"0"}},[(_vm.dataEdit.images[0])?_c('v-carousel',{staticClass:"rounded-lg",attrs:{"height":"250px","hide-delimiters":"","cycle":"","show-arrows":_vm.dataEdit.images.length > 1 ? true : false,"interval":"4000"}},_vm._l((_vm.dataEdit.images),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item,"reverse-transition":"slide-x-reverse-transition","transition":"slide-x-transition"}},[_c('div',{staticClass:"banner-modal"},[_c('div',{staticClass:"wrap-text-banner"},[_c('h3',[_vm._v(_vm._s(_vm.dataEdit.name))]),_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.dataEdit.description)+" ")])])])])}),1):_c('div',{staticClass:"banner-modal-no-image"},[_c('div',{staticClass:"wrap-text-banner"},[_c('h3',[_vm._v(_vm._s(_vm.dataEdit.name))]),_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.dataEdit.description)+" ")])])]),_vm._l((_vm.addonsShortEdit),function(ad,index){return _c('div',{key:index},[(
                  ad.products.length != 0 &&
                  ad.deleted == false &&
                  ad.active == true
                )?_c('CardTemplate',{staticClass:"mt-8",attrs:{"titulo":ad.name,"color":_vm.color,"required":ad.required,"label":"Obligatorio"}},[(!ad.products)?_c('v-skeleton-loader',{attrs:{"max-width":"100%","type":"date-picker-options,divider,date-picker-options,divider,date-picker-options,divider"}}):_vm._e(),(ad.products)?_c('Controler',{attrs:{"datos":ad.products,"type":ad.controlType,"Required":ad.required,"color":_vm.color,"addos_id":ad.id,"VControler":_vm.validControler,"max":ad.max,"valueEdit":ad.valueResp,"min":isNaN(ad.min) ? 0 : ad.min},on:{"respalvalue":(r) => {
                      if (r) {
                        ad.valueResp = r;
                      } else {
                        delete ad.valueResp;
                      }
                    },"getResul":(pr) => {
                      ad.calValue = pr;
                    },"price":_vm.calcular,"reCalcu":_vm.calcular}}):_vm._e()],1):_vm._e()],1)}),_c('v-row',{staticClass:"px-2 mt-4 mb-12"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"filled":"","auto-grow":"","rounded":"","required":false,"dense":"","label":"Comentario :","rows":"1","row-height":"30"},model:{value:(_vm.order.comments),callback:function ($$v) {_vm.$set(_vm.order, "comments", $$v)},expression:"order.comments"}})],1)],1)],2):_vm._e(),_c('div',{staticClass:"sticky-bottom px-3 py-3"},[_c('v-card-actions',{staticClass:"control-btn"},[_c('div',{staticClass:"contol"},[_c('v-btn',{attrs:{"icon":"","small":"","outlined":""},on:{"click":function($event){_vm.order.quantity <= 1 ? _vm.order.quantity : _vm.order.quantity--,
                      _vm.calcular()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1),_c('div',{staticClass:"resultado"},[_c('h3',[_vm._v(_vm._s(_vm.order.quantity))])]),_c('v-btn',{attrs:{"color":_vm.color,"small":"","outlined":"","icon":""},on:{"click":_vm.sumarQuality}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-btn',{staticClass:"rounded-lg btn-agregar",attrs:{"color":_vm.color,"width":"60%","height":"40px","elevation":"0"},on:{"click":_vm.validate}},[_vm._v(" Editar "),_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm._f("currency")(_vm.priceDOM)))])])],1)],1)],1)])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }