<template>

    <v-dialog
      v-model="dialog"
      :fullscreen="mobile"
      fullWidth="true"
      transition="dialog-bottom-transition"
      persistent
      max-width="500px"
    >
      <template>
        <div class="form" :style="`border-radius: ${mobile ? '0px':'15px'} !important`">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="nav-back-closed">
              <v-btn class="btn" @click="$emit('closed', false)" icon large>
                <v-icon color="#fff"> mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="modal-wrap-main">
              <v-card
                class="modal-modiu wrap-trs"
                elevation="0"
                v-if="dataEdit != null"
              >
                <v-carousel
                  v-if="dataEdit.images[0]"
                  height="250px"
                  hide-delimiters
                  cycle
                  :show-arrows="dataEdit.images.length > 1 ? true : false"
                  class="rounded-lg"
                  interval="4000"
                >
                  <v-carousel-item
                    v-for="(item, i) in dataEdit.images"
                    :key="i"
                    :src="item"
                    reverse-transition="slide-x-reverse-transition"
                    transition="slide-x-transition"
                  >
                    <div class="banner-modal">
                      <div class="wrap-text-banner">
                        <h3>{{ dataEdit.name }}</h3>
                        <p class="caption">
                          {{ dataEdit.description }}
                        </p>
                      </div>
                    </div>
                  </v-carousel-item>
                </v-carousel>
                <div v-else class="banner-modal-no-image">
                  <div class="wrap-text-banner">
                    <h3>{{ dataEdit.name }}</h3>
                    <p class="caption">
                      {{ dataEdit.description }}
                    </p>
                  </div>
                </div>

                <div v-for="(ad, index) in addonsShortEdit" :key="index">
                  <CardTemplate
                    v-if="
                      ad.products.length != 0 &&
                      ad.deleted == false &&
                      ad.active == true
                    "
                    class="mt-8"
                    :titulo="ad.name"
                    :color="color"
                    :required="ad.required"
                    label="Obligatorio"
                  >
                    <v-skeleton-loader
                      v-if="!ad.products"
                      max-width="100%"
                      type="date-picker-options,divider,date-picker-options,divider,date-picker-options,divider"
                    ></v-skeleton-loader>
                    <Controler
                      :datos="ad.products"
                      v-if="ad.products"
                      :type="ad.controlType"
                      :Required="ad.required"
                      :color="color"
                      :addos_id="ad.id"
                      :VControler="validControler"
                      :max="ad.max"
                      :valueEdit="ad.valueResp"
                      @respalvalue="
                        (r) => {
                          if (r) {
                            ad.valueResp = r;
                          } else {
                            delete ad.valueResp;
                          }
                        }
                      "
                      :min="isNaN(ad.min) ? 0 : ad.min"
                      @getResul="
                        (pr) => {
                          ad.calValue = pr;
                        }
                      "
                      @price="calcular"
                      @reCalcu="calcular"
                    />
                  </CardTemplate>
                </div>
                <v-row class="px-2 mt-4 mb-12">
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      filled
                      auto-grow
                      class="rounded-lg"
                      rounded
                      :required="false"
                      dense
                      label="Comentario :"
                      v-model="order.comments"
                      rows="1"
                      row-height="30"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
              <div class="sticky-bottom px-3 py-3">
                <v-card-actions class="control-btn">
                  <div class="contol">
                    <v-btn
                      icon
                      small
                      outlined
                      @click="
                        order.quantity <= 1 ? order.quantity : order.quantity--,
                          calcular()
                      "
                    >
                      <v-icon small> mdi-minus </v-icon>
                    </v-btn>
                    <div class="resultado">
                      <h3>{{ order.quantity }}</h3>
                    </div>
                    <v-btn
                      :color="color"
                      small
                      outlined
                      icon
                      @click="sumarQuality"
                    >
                      <v-icon small> mdi-plus </v-icon>
                    </v-btn>
                  </div>
                  <v-btn
                    :color="color"
                    width="60%"
                    height="40px"
                    class="rounded-lg btn-agregar"
                    @click="validate"
                    elevation="0"
                  >
                    Editar <b class="ml-3">{{ priceDOM | currency }}</b>
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-form>
        </div>
      </template>
    </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import CardTemplate from "@/components/cardTemplate";
import Controler from "@/components/controler";
import store from "@/store/index.js";
export default {
  name: "DialogEdit",
  components: {
    CardTemplate,
    Controler,
  },
  props: {
    id_product: {
      typeof: String,
    },
    data: {
      typeof: [Object, Array],
    },
    dialog: {
      typeof: Boolean,
      default: false,
    },
    color: {
      typeof: String,
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  computed: {
    addonsShortEdit() {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.dataEdit.addonsArray.sort(compare);
    },
  },
  data() {
    return {
      valid: true,
      price: 0,
      priceDOM: 0,
      dataEdit: null,
      alert: false,
      validControler: true,
      mobile: screen.width <= 550 ? true : false,
      productosArray: [],
      loading: false,
      order: {
        addons: [],
        comments: "",
        id: "",
        quantity: 1,
      },
    };
  },
  mounted: function () {
    this.dataEdit = JSON.parse(JSON.stringify(this.data));
    if (this.dataEdit.isDiscount == true) {
      this.priceDOM = this.dataEdit.discountPrice;
      
      this.price = this.dataEdit.discountPrice;
    } else {
      this.priceDOM = this.dataEdit.price;
      this.price = this.dataEdit.price;
    }
    this.order.quantity = this.dataEdit.orderP.quantity;
    this.order.comments = this.dataEdit.orderP.comments;
    this.priceDOM = this.priceDOM * this.order.quantity;
  },
  destroyed() {
    this.dataEdit = {};
  },
  methods: {
    ...mapActions(["Alert_", "addRespalOrders", "remove", "orderCanti","order_Edit"]),
        sumarQuality() {
      if (this.dataEdit.isStockRequiered) {
        // // console.log("no");
        if (this.order.quantity >= Number(this.dataEdit.stockAmount)) {
          this.Alert_({
            text: "Has alcanzado el máximo de inventario de este producto. 😬",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "fa-solid fa-check",
            type: "error",
          });
          return;
        }
      }

      if (this.dataEdit.userMax > 0) {
        if (this.order.quantity < this.dataEdit.userMax) {
          this.order.quantity++;
        } else {
          this.Alert_({
            text: "Has alcanzado el limite permitido para este producto. 😬",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "fa-solid fa-check",
            type: "error",
          });
        }
      } else {
        this.order.quantity++;
      }

      this.calcular();
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.validControler = true;
        this.UpdateOrder();
      } else {
        this.validControler = false;
        this.Alert_({
          text: "Favor selecciona las opciones requeridas 🙂",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "fa-solid fa-check",
          type: "error",
        });
      }
    },

    UpdateOrder() {
      this.order.id = this.dataEdit.id;
      // this.remove(this.data);
      this.dataEdit.orderP = this.order;
      this.dataEdit.priceDOM = this.priceDOM;
      // this.addRespalOrders(this.dataEdit);
      let datos = [this.data,this.dataEdit]
      this.order_Edit(datos);
      this.Alert_({
        text: "Producto Editado",
        timeout: 2000,
        btn_closed: false,
        icon: true,
        iconType: "mdi-pencil",
        type: "success",
      });
      datos = []
      this.$emit("closed", true);
    },
    calcular() {
      var calculo = 0;
      this.dataEdit.addonsArray.forEach((element) => {
        calculo = calculo + element.calValue || 0;
      });
      this.priceDOM = calculo + this.price;
      this.priceDOM = this.priceDOM * this.order.quantity;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
</style>
