<template>
  <div v-if="!loading">
    <div
      class="wrap-list-prd my-2 card"
      v-for="item in orders"
      :key="item.IdRespal"
      @click="(tap = !tap), (active = item.IdRespal)"
    >
      <!-- :class="{
          tap_active: tap == true && item.IdRespal == active,
        }" -->
      <div class="wrap-prd rounded-lg">
        <v-list-item-content class="rounded-lg">
          <v-list-item-title v-if="item && item.businessData">
            <v-avatar size="30" class="mb-1">
              <v-img
                :src="item.businessData.logo.original || imgDefault"
                :lazy-src="item.businessData.logo.loading || imgDefault"
              ></v-img>
            </v-avatar>
            <b class="ml-2" style="line-height: 0">{{
              item.businessData.shortName
            }}</b>
          </v-list-item-title>

          <v-list-item-title
            class="text-captionfont-weight-black"
            style="max-width: 350px; font-weight: 700"
            ><b class="quality">{{ item.orderP.quantity }}x </b
            ><span class="text-captionfont-weight-black"
              >{{ item.name }} ({{
                item.isDiscount ? item.discountPrice : item.price | currency
              }})
            </span>
          </v-list-item-title>

          <v-list-item-subtitle class="caption" v-if="item.addonsArray.filter(
                (e) => e.valueResp)">
            <ul
              v-for="(addon, index) in item.addonsArray.filter(
                (e) => e.valueResp
              )"
              :key="index"
            >
              <li>
                <span
                  class="text-addons-captions"
                  style="max-width: 250px; font-size: 15px"
                  v-if="addon.controlType == 'radio'"
                >
                  {{ addon.valueResp.originalProduct.name }} ({{
                    addon.valueResp.price | currency
                  }})</span
                >
              </li>
              <li
                v-for="(i, index) in Object.assign([], addon.valueResp)"
                :key="index"
              >
                <span
                  class="text-addons-captions"
                  style="max-width: 250px; font-size: 15px"
                  v-if="addon.controlType == 'checkbox'"
                >
                  {{ i.originalProduct.name }} ({{ i.price | currency }})</span
                >
              </li>
              <li
                v-for="(spin, index) in Object.assign(
                  [],
                  addon.products.filter((x) => x.resul > 0)
                )"
                :key="index"
              >
                <span
                  class="text-addons-captions"
                  style="max-width: 250px; font-size: 15px"
                  v-if="addon.controlType == 'spin'"
                >
                  ({{ spin.resul }}x)-{{ spin.originalProduct.name }} ({{
                    spin.price | currency
                  }})</span
                >
              </li>
            </ul>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="caption"
            ><b class=""
              >Total del producto ({{ item.priceDOM | currency }})</b
            >
          </v-list-item-subtitle>
        </v-list-item-content>
        <div
          class="item-image-order-card"
          v-if="item.images[0]"
          :style="`background-image:url('${item.images[0]}');`"
        ></div>
      </div>
      <!-- <div class="linea-tap" :style="` background-color: ${color};`"></div> -->
      <div class="btn_delete-edit">
        <div class="edit" @click="EditI(item)">
          <v-icon color="#b37c02">mdi-pencil</v-icon>
        </div>
        <div class="delete" @click="deleteI(item)">
          <v-icon color="#c10008">mdi-delete</v-icon>
        </div>
      </div>
      <!-- <transition name="slide-fade">
        <div class="wrap-actions" v-if="tap == true">
          <div class="edit rounded-lg" @click="EditI(item)">
            <v-icon color="#b37c02">mdi-pencil</v-icon>
          </div>
          <div class="delete rounded-lg" @click="deleteI(item)">
            <v-icon color="#c10008">mdi-delete</v-icon>
          </div>
        </div>
      </transition> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "itemListProduct",
  props: ["orders", "color"],

  data: () => ({
    tap: false,
    active: "",
    loading: true,
  }),
  computed: {
    ...mapGetters(["allBusiness", "infoData", "CarShop"]),
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },

  async mounted() {
    this.loading = true;
    await this.$nextTick(() => {
      if (this.allBusiness) {
        // console.debug("sasa");
        for (let i = 0; i < this.orders.length; i++) {
          const element = this.orders[i];
          var search = this.allBusiness.find(
            (e) => e[`.key`] == element.business[0]
          );
          element.businessData = search ? search : null;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  },
  methods: {
    EditI(item) {
      this.$emit("editI", item);
    },
    deleteI(item) {
      this.$emit("deleteItem", item);
    },
  },
};
</script>

<style lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";
.linea-tap {
  min-height: 55px;
  border-radius: 2px;
  width: 4px;
  max-height: 90% !important;
  margin-left: 5px;
}
.quality {
  margin-left: 0px !important;
}
.shoop-lottie {
  width: 200px;
  height: 200px;
}
.w-wrap-shopping {
  cursor: pointer;
}

@mixin btn-actions-prd {
  color: white !important;
  width: 40px;
  height: 100%;
}
.flex-center {
  @include flex-center;
  flex-direction: column;
}
.tap_active {
  transform: translateX(-100px) !important;
}
.btn_delete-edit {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 15px 15px;
  z-index: 2;

  .edit {
    @include flex-center;
    height: 100%;
    width: 50%;
    background-color: #ffdd6f;
    border-radius: 0px 0px 0px 15px;
  }
  .delete {
    @include flex-center;

    width: 50%;
    height: 100%;
    border-radius: 0px 0px 15px 0px;
    background-color: #ff7272;
  }
}

.wrap-list-prd {
  width: 100%;
  min-height: 75px;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;

  .wrap-prd {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: 100%;
    color: #212121 !important;
    padding: 5px 10px 5px 10px;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s ease-in-out;
    transform: translateX(0px);
    background-color: #f4f4f4;
    z-index: 1;
    padding-bottom: 50px !important;
  }
  .wrap-actions {
    width: 100px;
    min-height: 74px;
    height: 100%;
    position: relative;
    right: 0px;
    top: 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    .edit {
      @include flex-center;
      @include btn-actions-prd;
      background-color: #ffdd6f;
    }
    .delete {
      @include flex-center;
      @include btn-actions-prd;
      background-color: #ff7272;
    }
  }
}
.total-order {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.color-white {
  color: white !important;
}
.v-dialog__content {
  background-color: rgba(0, 0, 0, 0.227) !important;
}
.wrap-bandera {
  width: 100px;
  height: 20px;
  padding: 5px;
}
.wrap-flag {
  position: relative;
  .flag {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
      height: 35px;
      border-radius: 8px;
    }
    .select-flag {
      height: 250px;
      width: 100px;
      left: 0;
      top: 0;
      overflow-y: scroll;
      position: absolute;
      background-color: #f2f2f2;
      z-index: 100;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.435) 2px 2px 20px;
    }
  }
}
.list {
  background-color: #f2f2f2;
  overflow: scroll;
  border-radius: 20px;
}
.list-item {
  width: 100%;
  border-bottom: 1px rgba(134, 134, 134, 0.13) solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #f2f2f2;
  .img-text {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 30px;
      height: 20px;
      border-radius: 5px;
    }
    p {
      font-size: 12px;
      color: #212121;
      margin-bottom: 0px;
      line-height: 0px;
    }
  }
}

// @include responsive(desktop) {
// 	font-size: 20px;
// 	//... all what you want
// }
ul {
  padding-left: 5px !important;
  li {
    list-style: none;
  }
}

.lista {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.wrap-secctions {
  border-radius: 1em;
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  box-shadow: rgba(124, 124, 124, 0.212) 0px 15px 30px;
  position: relative;
  margin-top: 120px;
  margin-bottom: 100px;

  @include responsive(mobile) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.wrap-content {
  margin-top: 270px;
}
.total {
  width: 100%;
  margin-top: 20px;
  height: auto;
  display: flex;
  justify-content: center;

  align-items: center;
  flex-direction: column;
}
// .activo{
//   box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.123);
// }
.item-image-order-card {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-position: center;
  background-size: cover;
  @include flex-center;
  // margin-right: 10px;
  // margin-top: 5px;
  // margin-bottom: 5px;

  h3 {
    color: white;
    text-shadow: rgba(0, 0, 0, 0.603) 0px 0px 20px;
  }



}
.text-addons-captions{
  font-size: 14px !important;

}
</style>

