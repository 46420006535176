<template>
  <v-card class="text-center" style="border-radius:15px">
    <v-card-text class="mx-auto border-b" max-width="600" elevation="0">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            <h2>
              ¿Eliminar <b>x{{ data.orderP.quantity }} - {{ data.name }}</b
              >?
            </h2></v-list-item-title
          >
          <v-list-item-subtitle class="font-weight-light">
            {{ data.description }}</v-list-item-subtitle
          >

          <v-list-item-title
            :style="`color: ${color};`"
            class="text-h5 font-weight-bold"
          >
            {{ data.priceDOM | currency }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="product-detail">
        <v-list-item-avatar
          v-if="data.images[0]"
          tile
          class="rounded-lg"
          color="#f2f2f2"
          size="150"
        >
          <v-img
            :src="data.images[0]"
            lazy-src="@/assets/preloader-02.svg"
            class="rounded-lg"
          ></v-img>
        </v-list-item-avatar>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        width="50%"
        height="50px"
        :color="color"
        elevation="0"
        outlined
        class="rounded-lg"
        @click="$emit('close', false, data)"
        >Cancelar
      </v-btn>
      <v-btn
        width="50%"
        height="50px"
        :color="color"
        dark
        class="rounded-lg"
        elevation="0"
        @click="$emit('close', true, data)"
      >
        Aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import productList from "@/components/productList.vue";
export default {
  name: "Dialog",
  components: {
    productList,
  },
  props: {
    dialog: {
      typeof: Boolean,
      default: false,
    },
    data: {
      typeof: Object,
    },
    color: {
      typeof: String,
    },
  },
  data() {
    return {};
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";
.modal-Eliminar {
  border-radius: 1em;
  padding: 10px;
  text-justify: center;
}
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-modal {
  height: 250px;
  width: 100%;
  display: flex;
  color: rgb(177, 177, 177);
  justify-content: center;
  align-items: flex-end;
  padding: 10px;

  background-size: cover;
  background-position: center;
  border-radius: 1em;
}
.v-sheet.modal-modiu {
  border-radius: 1em !important;
  // padding: 10px;
  text-align: center;
}
.wrap-text-banner {
  background-color: rgba(32, 32, 32, 0.418);
  backdrop-filter: saturate(180%) blur(10px);
  border-radius: 1em;
  padding: 10px;
}
</style>
