<template>
  <v-card style="border-radius: 15px" class="">
    <v-card-title>
      <h2 style="font-weight: 800; color: #ff5900">Confirmar transacción</h2>
      <p style="font-size: 18px; font-weight: 300">
        ¿Está seguro que desea completar el pago?
      </p>
    </v-card-title>

    <v-card-text>
      <div>
        <v-row class="">
          <v-col class="font-weight-bold" cols="4" sm="4" md="4">
            Tipo de Tarjeta
          </v-col>
          <v-col cols="6" sm="6" md="6">
            {{ creditCardType }}
          </v-col>

          <v-col class="font-weight-bold" cols="4" sm="4" md="4">
            Titular
          </v-col>
          <v-col cols="6" sm="6" md="6">
            {{ card.name }}
          </v-col>

          <v-col class="font-weight-bold" cols="4" sm="4" md="4">
            Número de Tajeta
          </v-col>
          <v-col cols="6" sm="6" md="6">
            {{ card.number }}
          </v-col>

          <v-col class="font-weight-bold" cols="4" sm="4" md="4">
            Fecha de vencimiento
          </v-col>
          <v-col cols="6" sm="6" md="6">
            {{ card.expiresAt.slice(0, 2) }}/{{ card.expiresAt.slice(2, 4) }}
          </v-col>

          <v-col class="font-weight-bold" cols="4" sm="4" md="4"> Total </v-col>
          <v-col cols="6" sm="6" md="6">
          <span style="font-weight: 600; color: #ff5900"> <b>{{ amount | currency }}</b> </span>  
          </v-col>
        </v-row>
      </div>
    </v-card-text>

    <v-card-actions class="py-4">
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-btn
            text
            style="border-radius: 15px"
            rounded
            outlined
            block
            height="50"
            :loading="loading"
            @click="$emit('cancel')"
            >Cancelar</v-btn
          >
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-btn
            color="primary"
            style="
              border-radius: 15px;
              box-shadow: 0px 4px 10px 5px rgba(255, 89, 0, 0.2);
            "
            block
            :loading="loading"
            height="50"
            @click="$emit('success')"
            >Confirmar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: ["link", "card", "loading", "amount"],
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L.${formatter.format(amount)}`;
    },
  },
  computed: {
    creditCardType() {
      let amex = new RegExp("^3[47][0-9]{13}$");
      let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
      let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
      let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

      let mastercard = new RegExp("^5[1-5][0-9]{14}$");
      let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

      let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
      let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
      let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

      let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
      let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

      if (visa.test(this.card.number)) {
        return "VISA";
      }
      if (amex.test(this.card.number)) {
        return "AMEX";
      }
      if (
        mastercard.test(this.card.number) ||
        mastercard2.test(this.card.number)
      ) {
        return "MASTERCARD";
      }
      if (
        disco1.test(this.card.number) ||
        disco2.test(this.card.number) ||
        disco3.test(this.card.number)
      ) {
        return "DISCOVER";
      }
      if (diners.test(this.card.number)) {
        return "DINERS";
      }
      if (jcb.test(this.card.number)) {
        return "JCB";
      }
      if (cup1.test(this.card.number) || cup2.test(this.card.number)) {
        return "CHINA_UNION_PAY";
      }
      return undefined;
    },
  },
};
</script>
